
import HTTP from "@utils/http";
// 学生注册
export async function student_register(prams) {
	try {
	return await HTTP.post("/studentpc/stuuser/student_register", prams);
	} catch (error) {
	return error;
	}
}
// 学生账密登录
export async function account_login(prams) {
	try {
	return await HTTP.post("/studentpc/stuuser/account_login", prams);
	} catch (error) {
	return error;
	}
}

// 修改登录密码
export async function update_pwd(prams) {
	try {
	return await HTTP.post("/studentpc/stuuser/update_pwd", prams);
	} catch (error) {
	return error;
	}
}

// 修改用户信息重新审核
export async function register_again(prams) {
	try {
	return await HTTP.post("/studentpc/stuuser/register_again", prams);
	} catch (error) {
	return error;
	}
}
