<!--
 * @Author: Dyf
 * @LastEditors: Please set LastEditors
 * @Date: 2023-03-22 18:14:02
 * @LastEditTime: 2023-11-08 16:55:26
 * @Descripttion: 个人中心
-->
<style lang="scss" scoped>
.form-box {
      width: 100%;
      height: 668px;
      border-radius: 30px;
      background: #fff;
      margin-right: 30px;
      box-sizing: border-box;
      padding: 24px 68px;
      max-width: 752px;
      margin-top: 40px;

      h5 {
        color: #3e3e3e;
        line-height: 2em;
        padding-left: 40px;
        margin-bottom: 22px;
        text-align: center;
        font-size: 20px;
      }

      .el-form-item {
        margin-top: 32px;

        &.code {
          ::v-deep {
            .el-input__inner {
              padding-right: 130px;
            }
          }

          .el-button {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            margin-top: 0;
          }
        }
      }

      ::v-deep .el-input__inner {
        height: 60px;
        line-height: 60px;
      }
      .setsex {
        width: 100%;
        display: flex;
        .sexmodel {
          margin-right: 20px;
          width: 140px;
          height: 60px;
          text-align: center;
          line-height: 60px;
          border-radius: 10px;
          background: #f6f7fa;
          color: #333333;
          font-size: 16px;
          &.sexopt {
            background-color: #6340c8;
            color: #ffffff;
          }
          &:hover {
            background-color: #6340c8;
            color: #ffffff;
            cursor: pointer;
          }
        }
      }
      .el-button:not(.el-button--text) {
        width: 100%;
        font-size: 18px;
        color: #fff;
      }


      .register-form {
        .el-form-item {
          margin-top: 20px;
        }

        .upload {
          margin: 32px 0;
        }
      }

      .password-form {
        .el-form-item {
          margin-top: 24px;
        }

        .el-button {
          margin-top: 56px;
        }
      }
    }
</style>

<template>
  <section class="form-box">
   <!-- 注册表 -->
   <div class="register-form">
      <el-form
        ref="registerForm"
        :rules="registerRules"
        :model="registerForm"
      >
        <h5 class="bold">修改资料</h5>
        <el-form-item prop="stuser_name">
          <el-input
            v-model.trim="registerForm.stuser_name"
            maxlength="20"
            placeholder="请输入姓名"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <div class="setsex">
            <div
              class="sexmodel"
              :class="registerForm.stuser_sex == 10 ? 'sexopt' : ''"
              @click="setSex(10)"
            >
              男
            </div>
            <div
              class="sexmodel"
              :class="registerForm.stuser_sex == 20 ? 'sexopt' : ''"
              @click="setSex(20)"
            >
              女
            </div>
          </div>
        </el-form-item>
        <!-- <el-form-item prop="stuser_stuno"> -->
        <el-form-item>
          <el-input
            v-model.trim="registerForm.stuser_stuno"
            maxlength="30"
            placeholder="请输入学号(非必填，可用数字/字母，不可用字符)"
            clearable
          />
        </el-form-item>
        <el-form-item prop="area">
          <el-cascader
            v-model="registerForm.area"
            ref="schoolArea"
            :options="provinceData"
            clearable
            :props="{ value: 'ddi_id', label: 'ddi_name' }"
            placeholder="请选择区域"
            @change="changeProvince"
          />
        </el-form-item>
        <el-form-item prop="scsch_id">
          <el-select
            v-model="registerForm.scsch_id"
            filterable
            clearable
            placeholder="请选择学校"
            @change="changeScsch"
          >
            <el-option
              v-for="item in schoolData"
              :key="item.scsch_id"
              :label="item.scsch_name"
              :value="item.scsch_id"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="sccla">
          <el-cascader
            v-model="registerForm.sccla"
            ref="graderef"
            :options="gradeData"
            clearable
            :props="{ value: 'sccla_id', label: 'sccla_name' }"
            placeholder="请选择班级"
            @change="changeGrade"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            class="bold"
            type="custom_primary"
            @click="handleRegister"
            :disabled="btnload"
            v-loading="btnload"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </section>
</template>

<script>
import {register_again}  from "@api/login";
import {
  $getProvinceData,
  $getSchool,
  $getGradeClass,
} from "@api/common";
import { mapActions } from "vuex";
export default {
  name: "updataAudit",
  data() {
    return {
      // 登录表单
      registerForm: {
        // 姓名
        stuser_name: "",
        // 学号
        stuser_stuno: "",
        // 性别
        stuser_sex: 10,
        scsch_province: 0,
        scsch_city: 0,
        scsch_area: 0,
      },
      // 注册表单
      provinceData: [], // 省市区数据
      schoolData: [], // 学校数据
      gradeData: [], // 班级数据
      /* 注册表单校验规则 */
      registerRules: {
        stuser_name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        // stuser_stuno: [
        //   { required: true, message: "请输入学号", trigger: "blur" },
        // ],
        area: [
          {
            type: "array",
            required: true,
            message: "请选择地区",
            trigger: "change",
          },
        ],
        scsch_id: [
          { required: true, message: "请选择学校", trigger: "change" },
        ],
        sccla: [
          {
            type: "array",
            required: true,
            message: "请选择班级",
            trigger: "change",
          },
        ],
      },
      // 注册按钮等待
      btnload: false,
    };
  },
  created() {
    this.getuserinfo()
    this.getProvinceData();
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    getuserinfo(){
      let userInfo=this.$store.state.user.userInfo
      this.registerForm={
        // 姓名
        stuser_name: userInfo.stuser_name,
        // 学号
        stuser_stuno: userInfo.stuser_stuno,
        // 性别
        stuser_sex: userInfo.stuser_sex,
        scsch_province: 0,
        scsch_city: 0,
        scsch_area: 0
      }
    },
    enterKey(event) {
      const code = event.keyCode
        ? event.keyCode
        : event.which
        ? event.which
        : event.charCode;
      if (code == 13) {
        this.handleLogin();
      }
    },
    //选中省市区
    changeProvince(val) {
      if (this.$isEmpty(val)) {
        this.registerForm.area = [];
        this.$nextTick(() => {
          this.$refs.schoolArea.$refs.panel.clearCheckedNodes();
          this.$refs.schoolArea.$refs.panel.activePath = [];
          this.$refs.graderef.$refs.panel.clearCheckedNodes();
          this.$refs.graderef.$refs.panel.activePath = [];
          this.registerForm.sccla=""
        });
      }

      // 删除选中的学校
      this.$set(this.registerForm, "scsch_id", null);
      this.getSchool(val);
    },
    // 获取省市区下属学校
    async getSchool(params) {
      let { data } = await $getSchool({
        scsch_province: params[0],
        scsch_city: params[1],
        scsch_area: params[2],
      });
      this.schoolData = data;
      this.$forceUpdate();
    },
    // 学校选中获取班级
    changeScsch(val) {
      this.$refs.graderef.$refs.panel.activePath = [];
      this.gradeData=[]
      if(val>0){
        this.getGradeClass();
      }
    },
    // 获取班级
    async getGradeClass() {
      let res = await $getGradeClass(this.registerForm.scsch_id);
      if (res) {
        this.gradeData = res.data;
      }
    },
    //班级选中
    changeGrade(val) {
      if (this.$isEmpty(val)) {
        this.$nextTick(() => {
          this.$refs.graderef.$refs.panel.clearCheckedNodes();
          this.$refs.graderef.$refs.panel.activePath = [];
          this.registerForm.sccla=""
        });
      }
    },
    /** 获取省市区数据 */
    async getProvinceData() {
      let { data } = await $getProvinceData();
      data.forEach((item) => {
        item.children = item.children.filter((citem) => citem.ddi_id != -1);
        item.children.map((citem) => {
          if (citem.children) {
            citem.children.length == 0 && delete citem.children;
          }
        });
        item.children.forEach((citem) => {
          if (citem.children) {
            citem.children = citem.children.filter(
              (aitem) => aitem.ddi_id != -1
            );
            citem.children.map(
              (aitem) => aitem.children.length == 0 && delete aitem.children
            );
          }
        });
      });
      this.provinceData = data;
      this.$forceUpdate();
    },
    /** 修改 */
    handleRegister() {
      this.$refs.registerForm.validate(async (valid) => {
        if (valid) {
          this.btnload = true;
          let params = {
            stuser_name: this.registerForm.stuser_name,
            stuser_sex: this.registerForm.stuser_sex,
            stuser_stuno: this.registerForm.stuser_stuno,
            stuser_province_id: this.registerForm.area[0],
            stuser_city_id: this.registerForm.area[1],
            stuser_area_id: this.registerForm.area[2],
            scsch_id: this.registerForm.scsch_id,
            sccla_id: this.registerForm.sccla[1],
          };
          let res = await register_again(params);
          this.btnload = false;
          // 注册成功后确认登录
          if (res) {
            this.$message.success("修改成功,等待审核");
            // 获取用户信息
            setTimeout(() => {
            // 获取用户信息
            this.getUserInfo();
            this.$router.replace({ name: "HOME" });
            }, 300);
          }
        }
      });
    },
    setSex(val) {
      this.registerForm.stuser_sex = val;
      this.$forceUpdate();
    },
  },
};
</script>